import { AciContext, AciSelector } from '@arena-labs/strive2-data';
import { $API } from '@strive/api';

export function useNeedsSleepNeedsACI() {
  const { data: progress } = $API.useGetProgressData();

  const isAciSkipped = AciContext.useSelector(AciSelector.isSkipped);
  const isAciComplete = AciContext.useSelector(AciSelector.isComplete);
  const aciLoggedToday = isAciSkipped || isAciComplete;

  const needsANightsSleep =
    progress?.results.length === 1 &&
    (progress.results?.[0]?.start === null ||
      progress.results?.[0]?.start === undefined);

  return [needsANightsSleep, aciLoggedToday];
}
