import {
  Box,
  Button,
  chakra,
  Fade,
  Flex,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';

import { DataTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { AciContext, AciSelector, useACIGate } from '@arena-labs/strive2-data';
import { TEXT, WaitForQuery } from '@arena-labs/strive2-ui';
import { $API, Wearable } from '@strive/api';
import { StrivewareContext, StrivewareSelector } from '@strive/wearable';

import { ImageAndGradientBackground } from '../practice/practice-detail/image-and-gradient-background';

export function AciCTACard() {
  const { actions } = useACIGate();
  const userQuery = $API.useGetUserProfile();
  const analytics = useAnalytics();
  const aciActor = AciContext.useActorRef();

  const isLoading = AciContext.useSelector(AciSelector.isLoading);
  const isComplete = AciContext.useSelector(AciSelector.isComplete);
  const isSkipped = AciContext.useSelector(AciSelector.isSkipped);
  const canResume = AciContext.useSelector(AciSelector.canResume);

  const deviceState = StrivewareContext.useSelector(
    StrivewareSelector.deviceState,
  );

  const skipAci = () => {
    analytics.logEvent(DataTrackingEvent.ACISkipped);
    aciActor.send({ type: 'skip' });
  };

  const openAci = (wearable?: Wearable) => {
    if (wearable?.provider) {
      aciActor.send({ type: 'open' });
      analytics.logEvent(DataTrackingEvent.ACIModalOpened);
    } else {
      actions.handleStartCheckIn();
    }
  };

  const openAciInsights = (wearable?: Wearable) => {
    if (wearable?.provider) {
      aciActor.send({ type: 'open' });
    } else {
      actions.openToResults();
    }
    analytics.logEvent(DataTrackingEvent.ACIInsightsOpened);
  };

  const strivewareActor = StrivewareContext.useActorRef();
  const reconnect = StrivewareContext.useSelector((state) =>
    state.can({ type: 'Retry' })
      ? () => strivewareActor.send({ type: 'Retry' })
      : state.can({ type: 'Sync' })
      ? () => strivewareActor.send({ type: 'Sync' })
      : null,
  );

  return (
    <Fade
      in={!isLoading && userQuery.status === 'success'}
      style={{ display: 'contents' }}
    >
      <WaitForQuery query={userQuery}>
        {(user) => {
          const wearable = user.profile.wearable[0];
          const isSyncedToday =
            wearable?.is_synced_today || deviceState === 'synced';
          return (
            <Box
              w={'full'}
              layerStyle={'1dp'}
              rounded={'card'}
              position={'relative'}
              zIndex={0}
            >
              <ImageAndGradientBackground
                image={'./images/aci-card-lines.webp'}
                gradient={
                  'linear-gradient(180deg, rgba(45, 45, 45, 0) 0%, #282828 39.85%)'
                }
              />

              <VStack
                w="full"
                px={4}
                gap={3}
                pt="40px"
                textAlign={'left'}
                position={'relative'}
                pb={5}
              >
                {isSkipped ? (
                  <>
                    <TEXT.H2 color={'neutral.white'} mr={'auto'}>
                      Arena Check-In skipped
                    </TEXT.H2>
                    <TEXT.P2 lineHeight={'tall'} color={'neutral.400'}>
                      You decided to skip today. Come back tomorrow for a new
                      check-in!
                    </TEXT.P2>
                  </>
                ) : isComplete ? (
                  <>
                    <TEXT.H2 color={'neutral.white'} mr={'auto'}>
                      Arena Check-In completed
                    </TEXT.H2>
                    <Button
                      variant={'underline-link'}
                      color={'white'}
                      onClick={() => openAciInsights(wearable)}
                    >
                      View today&apos;s insights
                    </Button>
                  </>
                ) : isSyncedToday ? (
                  <>
                    <TEXT.H2 color={'neutral.white'} mr={'auto'}>
                      Ring Synced Successfully!
                    </TEXT.H2>
                    <TEXT.P2
                      w={'full'}
                      as={Flex}
                      gap={'1'}
                      alignContent={'flex-start'}
                      flexDirection={'column'}
                      lineHeight={'tall'}
                      color={'neutral.400'}
                    >
                      <chakra.span>
                        Did you wear your ring during sleep?
                      </chakra.span>
                      <chakra.span>
                        Full insights require sleep data from last night.
                      </chakra.span>
                    </TEXT.P2>
                    <Button
                      w="full"
                      variant="primary"
                      onClick={() => openAci(wearable)}
                    >
                      {canResume ? 'Resume' : 'Start'} ACI
                    </Button>
                    <Button
                      variant="underline-link"
                      color="white"
                      onClick={() => skipAci()}
                      textStyle="p1"
                    >
                      Skip check-in and reveal data
                    </Button>
                  </>
                ) : deviceState === 'syncing' ||
                  deviceState === 'connecting' ? (
                  <>
                    <Text textStyle="h2" color="neutral.white">
                      Arena Check-In
                    </Text>
                    <Text textStyle="p2" color="neutral.400">
                      We&apos;re syncing your ring now to make sure you get the
                      most accurate results for your ACI.
                    </Text>
                    <Flex
                      w="full"
                      layerStyle="2dp"
                      direction="row"
                      align="center"
                      justify="center"
                      gap="2"
                      py="3"
                      borderRadius="card"
                      color="white"
                    >
                      <Spinner size="sm" />
                      <Text textStyle="p1_semibold">
                        {deviceState === 'connecting'
                          ? 'Connecting'
                          : 'Syncing data'}
                        ...
                      </Text>
                    </Flex>
                    <Button
                      variant="underline-link"
                      color="white"
                      onClick={() => skipAci()}
                    >
                      <Text textStyle="p1">Skip check-in and reveal data</Text>
                    </Button>
                  </>
                ) : (
                  <>
                    <Text textStyle="h2" color="neutral.white">
                      Connection Unsuccessful
                    </Text>
                    <Text textStyle="p2" color="neutral.400">
                      Ensure your ring is within 5-8 meters of your phone. If
                      the battery is low, place it on a charger to help
                      establish the connection.
                    </Text>
                    {reconnect && (
                      <Button
                        w="full"
                        variant="secondary"
                        onClick={() => reconnect?.()}
                      >
                        Reconnect
                      </Button>
                    )}
                    <Button
                      w="full"
                      variant="primary"
                      onClick={() => openAci(wearable)}
                    >
                      Start ACI without data
                    </Button>
                    <Button
                      variant="underline-link"
                      color="white"
                      onClick={() => skipAci()}
                    >
                      Skip check-in and reveal data
                    </Button>
                  </>
                )}
              </VStack>
            </Box>
          );
        }}
      </WaitForQuery>
    </Fade>
  );
}
